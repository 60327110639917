<template>
  <el-drawer :visible.sync="params.show" :before-close="close"  size="30%" :modal="false" :with-header="false" @open="openDrawer">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="封面" prop="cover_path">
        <image-upload v-model="ruleForm.cover_path"></image-upload>
      </el-form-item>
      <el-form-item label="时刻" prop="moment_time">
        <el-date-picker
          v-model="ruleForm.moment_time"
          align="right"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="内容文本(中文)" prop="text_cn">
        <el-input type="textarea" v-model="ruleForm.text_cn" :autosize="{ minRows: 6, maxRows: 4}"></el-input>
      </el-form-item>
      <el-form-item label="内容文本(英文)" prop="text_us">
        <el-input type="textarea" v-model="ruleForm.text_us" :autosize="{ minRows: 6, maxRows: 4}"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import { editDevelopment, addDevelopment } from '@/api/development/development'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    imageUpload
  },
  data() {
    return {
      ruleForm: {
        id: null,
        moment_time: '',
        cover_path: '',
        text_cn: '',
        text_us: ''
      },
      rules: {},
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        moment_time: '',
        cover_path: '',
        text_cn: '',
        text_us: ''
      }
      this.$emit('close')
    },
    openDrawer () {
      if (this.params.data) {
        this.ruleForm = this.params.data
        this.rules = {}
      } else {
        this.rules = {
          cover_path: [
            { required: true, message: '请上传封面', trigger: 'blur' },
          ],
          moment_time: [
            { required: true, message: '请选择时刻', trigger: 'blur' },
          ],
          text_cn: [
            { required: true, message: '请输入内容文本(中文)', trigger: 'blur' },
            { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' },
          ],
          text_us: [
            { required: true, message: '请输入内容文本(英文)', trigger: 'blur' },
            { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' },
          ],
        }
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = this.params.data ? await editDevelopment(this.ruleForm) : await addDevelopment(this.ruleForm)
        if (result.success) {
          this.$message.success('已完成')
          this.close()
        }
      })
    },
  },
  computed: {
  },
}
</script>
<style scoped lang="less">
  .el-form {
    margin: 10px;
  }
</style>
